import React, { useState, useEffect, useContext } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Image,
  Button,
  Flex,
  Text,
  HStack,
} from "@chakra-ui/react";
import {
  BrandTitle,
  FooterLogo,
  LoadingSpinner,
  PageHead,
  ScreenContainer,
} from "src/components";
import { UserContext } from "src/context/UserContext";
import { browserName, isIE, isSamsungBrowser } from "react-device-detect";

// Sanity
import { groq } from "next-sanity";
import client from "lib/client";

// Sanity fetch query
const query = groq`
*[_type =='landingPage'
 && _lang == $lang || _type == 'portalSettings' && _lang == $lang]{
   pageTitle,
   loginButton,
   signupButton,
   availableLanguage,
   aboutLinkText,
   aboutLinkURL,
   "image": mainImage.asset->url,
   "caption": mainImage.captionImage,
   "logo": uploadLogo.asset->url,
   "logoCaption": uploadLogo.caption,
   titlePartOne,
   titlePartTwo,
   "brandTitleImage": brandTitleImage.asset->url,
   "brandCaption": brandTitleImage.brandCaption
}
`;

export default function Home() {
  const [data, setData] = useState([]);
  const { language, storeLanguage, languages } = useContext(UserContext);
  const router = useRouter();

  // fetching sanity
  useEffect(() => {
    client
      .fetch(query, { lang: language })
      .then((res) => setData(res))
      .catch(console.error);
  }, [language, query]);

  // removing null properties
  data.forEach((obj) => {
    Object.keys(obj).forEach((k) => obj[k] == null && delete obj[k]);
  });

  // merge obects from sanity, destructure values
  const {
    aboutLinkText,
    aboutLinkURL,
    availableLanguage,
    caption,
    image,
    loginButton,
    logo,
    logoCaption,
    pageTitle,
    signupButton,
    titlePartOne,
    titlePartTwo,
    brandTitleImage,
    brandCaption,
  } = Object.assign({}, ...data);

  const navigate = (path) => {
    if (path) {
      // first parameter: pathname, second: as/alias, third: language
      router.push(`/auth/${path}`, `/auth/${path}`, { locale: language });
    }
  };

  if (!data.length) {
    return <LoadingSpinner />;
  }

  return (
    <ScreenContainer>
      <PageHead title={`${titlePartOne} ${titlePartTwo} :: ${pageTitle}`} />
      {brandTitleImage && (
        <Image src={brandTitleImage} alt={brandCaption} mb="1.5rem" />
      )}
      {!brandTitleImage && titlePartOne && titlePartTwo && (
        <BrandTitle titlePartOne={titlePartOne} titlePartTwo={titlePartTwo} />
      )}
      <Image src={image} alt={caption ? caption : "Front page image"} />
      {browserName == "Facebook" ? (
        <Alert status="info" bg={"#E42217"} color="white" mt={"1rem"}>
          <AlertIcon color="white" />
          <Box flex="1">
            <AlertTitle mr={2}>Siden bør åpnes i en annen nettleser</AlertTitle>
            <AlertDescription display="block">
              Du har åpnet siden i Facebooks nettleser. For å få registrert din
              progresjon ved gjennomføringen av kurset, vennligst åpne siden i
              en annen nettleser.
              <br />
              På iOS kan du klikke på … øverst eller nederst til høyre og velge
              &quot;Åpne i nettleseren&quot;.
            </AlertDescription>
          </Box>
        </Alert>
      ) : null}
      {browserName == "Instagram" ? (
        <Alert status="info" bg={"#E42217"} color="white" mt={"1rem"}>
          <AlertIcon color="white" />
          <Box flex="1">
            <AlertTitle mr={2}>Siden bør åpnes i en annen nettleser</AlertTitle>
            <AlertDescription display="block">
              <Text>
                Du har åpnet siden i Instagrams nettleser. For å få registrert
                din progresjon ved gjennomføringen av kurset, vennligst åpne
                siden i en annen nettleser.
                <br />
                På iOS kan du klikke på … øverst eller nederst til høyre og
                velge &quot;Åpne i nettleseren&quot;.
              </Text>
            </AlertDescription>
          </Box>
        </Alert>
      ) : null}
      {isSamsungBrowser ? (
        <Alert status="info" bg={"#E42217"} color="white" mt={"1rem"}>
          <AlertIcon color="white" />
          <Box flex="1">
            <AlertTitle mr={2}>Siden bør åpnes i en annen nettleser</AlertTitle>
            <AlertDescription display="block">
              For best opplevelse anbefaler vi at du benytter en oppdatert
              nettleser. På Android anbefaler vi å bruke Google Chrome.
            </AlertDescription>
          </Box>
        </Alert>
      ) : null}
      {isIE ? (
        <Alert status="info" bg={"#E42217"} color="white" mt={"1rem"}>
          <AlertIcon color="white" />
          <Box flex="1">
            <AlertTitle mr={2}>Siden bør åpnes i en annen nettleser</AlertTitle>
            <AlertDescription display="block">
              For best opplevelse anbefaler vi at du benytter en oppdatert
              nettleser. På Windows anbefaler vi Edge, Chrome eller Firefox.
            </AlertDescription>
          </Box>
        </Alert>
      ) : null}
      <Flex pt={6} justify="space-between" align="center">
        <Button mr={2} variant="standard" onClick={() => navigate("login")}>
          {loginButton}
        </Button>
        <Button ml={2} variant="standard" onClick={() => navigate("signup")}>
          {signupButton}
        </Button>
      </Flex>
      <HStack
        mt={6}
        spacing="4px"
        align="center"
        justify="center"
        textTransform="uppercase"
      >
        {availableLanguage && (
          <>
            <Button
              bg="transparent"
              padding="3px 0 0"
              fontWeight="normal"
              fontSize="0.96875rem"
              textTransform="uppercase"
              color="link"
              _hover={{ bg: "transparent", textDecoration: "underline" }}
              _active={{ bg: "transparent" }}
              _focus={{ bg: "transparent" }}
              onClick={() =>
                storeLanguage(
                  language === languages[0] ? languages[1] : languages[0]
                )
              }
            >
              {availableLanguage}
            </Button>
            <Text>|</Text>
          </>
        )}
        {aboutLinkURL && aboutLinkText && (
          <Link href={`/home${aboutLinkURL}`} locale={language}>
            {aboutLinkText}
          </Link>
        )}
      </HStack>
      <FooterLogo logo={logo} caption={logoCaption} />
    </ScreenContainer>
  );
}
